import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AiOutlineMenu, AiOutlineClose, AiFillCaretDown } from "react-icons/ai"
import { getGatsbyImageData } from "gatsby-source-sanity"

import NavbarItem from "./NavbarItem"
import { sanityConfig } from "../../sanityConfig"
import WinksLogo from "../svgs/WinksLogo"
import WinksLogoTextOnly from "../svgs/WinksLogoTextOnly"
import WinksLogoCircleOnly from "../svgs/WinksLogoCircleOnly"

const NavGridContainer = ({ children }) => (
  <div className="bg-white  grid grid-flow-col place-items-center z-10">
    {children}
  </div>
)

const MobileNavLink = ({ to = "#", className, name = "*Error*" }) => (
  <li className="grid uppercase ">
    <Link
      to={to}
      title={name}
      activeClassName="bg-secondary text-white"
      className="hover:bg-secondary hover:text-tertiary py-3 pl-5"
    >
      {name}
    </Link>
  </li>
)

const NavBar = () => {
  const [open, setOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query NavbarQuery {
      sanityLogo: sanitySettings {
        logoImage {
          asset {
            id
            url
          }
        }
      }
      defaultNavLogo: file(relativePath: { eq: "defaultLogo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 200, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const { defaultNavLogo, sanityLogo } = data

  const logoImageData = sanityLogo?.logoImage?.asset
    ? getGatsbyImageData(
        sanityLogo.logoImage.asset,
        { maxHeight: 200 },
        sanityConfig
      )
    : null

  // modify this to accept logo from sanity
  const navLogo = logoImageData || defaultNavLogo.childImageSharp

  // These are links for the mobile menu
  const navLinks = [
    {
      name: "WINKS",
      handle: "winks",
      key: "winks",
    },
    {
      name: "Home",
      handle: "home",
      key: "home",
    },
    {
      name: "About Us",
      handle: "about",
      key: "about",
    },
    {
      name: "News",
      handle: "news",
      key: "news",
    },
    {
      name: "Programs",
      handle: "/programs",
      key: "programs",
    },
    {
      name: "Events",
      handle: "events",
      key: "events",
    },
    {
      name: "Contact Us",
      handle: "contact",
      key: "contact",
    },

    {
      name: "Resources",
      handle: "resources",
      key: "resources",
    },
    // {
    //   name: "Calendar",
    //   handle: "calendar",
    //   key: "calendar",
    // },
    {
      name: "Gallery",
      handle: "gallery",
      key: "gallery",
    },
  ]

  // Links for the "More" menu
  const moreLinks = [
    {
      name: "Programs",
      handle: "/programs",
      key: "programs",
    },
    { name: "Resources", handle: "/resources", key: "resources" },

    // { name: "Calendar", handle: "/calendar", key: "calendar" },
    {
      name: "Gallery",
      handle: "/gallery",
      key: "gallery",
    },
    {
      name: "Admin",
      url: "https://winks.sanity.studio",
      key: "admin",
    },
  ]
  // <Img className="w-20" fluid={navLogo.fluid} />
  return (
    <header className="">
      <nav>
        {/* navbar logo */}
        {/* desktop nav */}
        <div className="flex justify-between items-center lg:items-stretch lg:justify-start  ">
          <NavbarItem
            handle=""
            name="WINKS"
            fluid={{
              inactive: "bg-winks-logo bg-cover bg-center",
              active: "bg-winks-logo-selected bg-cover bg-center",
            }}
            className="ml-5"
          >
            <span className="flex items-center h-10 m-2 lg:ml-5">
              <WinksLogoCircleOnly className="h-full  fill-tertiary mr-2" />
              <WinksLogoTextOnly className="fill-tertiary h-4/6" />
            </span>
          </NavbarItem>
          <div className="hidden lg:grid lg:grid-flow-col  justify-center  sm:gap-x-16  xl:gap-x-32 w-full ">
            <NavbarItem handle="" name="Home" />
            <NavbarItem handle="about" name="About" />
            <NavbarItem handle="events" name="Events" />
            <NavbarItem handle="news" name="News" />
            <NavbarItem handle="contact" name="Contact" />
            <NavbarItem
              name={
                <div className="flex items-center">
                  <span className="pr-2">More</span>
                  {<AiFillCaretDown />}
                </div>
              }
              className="hidden md:block"
              dropdown={moreLinks}
              dropdownWrap
            />
          </div>

          {/* Burger Icon */}
          <button
            className="grid lg:hidden mr-5 text-2xl uppercase cursor-pointer text-tertiary text-center h-full  items-center py-2 font-bold "
            title="Navigation Menu"
            onClick={() => setOpen(!open)}
          >
            {open ? <AiOutlineClose /> : <AiOutlineMenu />}
          </button>
        </div>
      </nav>
      {/* <div className="fixed block w-full h-full text-white z-10 bg-gray-500  bg-opacity-60">
        Overlay
      </div> */}
      {/* Burger/Mobile Menu */}
      {open && (
        <div className=" bg-primary text-xl text-white lg:hidden">
          <ul className="flex flex-col p-2.5">
            <MobileNavLink name="Home" to="/" />
            <MobileNavLink name="About" to="/about" />
            <MobileNavLink name="News" to="/news" />
            <MobileNavLink name="Programs" to="/programs" />
            <MobileNavLink name="Resources" to="/resources" />
            <MobileNavLink name="Gallery" to="/gallery" />
            <MobileNavLink name="Events" to="/events" />
            <MobileNavLink name="Contact Us" to="/contact" />
            {/* <MobileNavLink name="Admin" to="" /> */}
            <li className="grid uppercase ">
              <a
                href={`https://winks.sanity.studio/`}
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="bg-secondary text-white"
                className="hover:bg-secondary hover:text-tertiary py-3 pl-5"
                title="Admin (new window)"
              >
                Admin
              </a>
            </li>
          </ul>
        </div>
      )}
    </header>
  )
}

export default NavBar
