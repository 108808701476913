import React from "react"
import { FaFacebook, FaTwitter, FaInstagram, FaCopyright } from "react-icons/fa"
import { RiCopyrightLine } from "react-icons/ri"
import FooterForm from "./footerForm"
import { Link } from "gatsby"
import WaveDivider from "./svgs/WaveDivider"
import ExternalLink from "./ExternalLink"

const FooterSection = ({ title = "Title", className = "", children }) => (
  <div className={`flex flex-col items-center text-center ${className}`}>
    <div className="text-2xl">{title}</div>
    <WaveDivider className="w-1/3 fill-secondary py-3" />
    {children}
  </div>
)

const footerLinks = [
  {
    name: "Home",
    handle: "",
    key: "home",
  },
  {
    name: "About",
    handle: "about",
    key: "about",
  },
  {
    name: "News",
    handle: "news",
    key: "news",
  },
  {
    name: "Events",
    handle: "events",
    key: "events",
  },
  {
    name: "Resources",
    handle: "resources",
    key: "resources",
  },
  {
    name: "Gallery",
    handle: "gallery",
    key: "gallery",
  },
  {
    name: "Contact",
    handle: "contact",
    key: "contact",
  },
  // {
  //   name: "COVID-19",
  //   handle: "covid",
  //   key: "covid",
  // },

  // {
  //   name: "Privacy",
  //   handle: "privacy",
  //   key: "privacy",
  // },
  // {
  //   name: "Policy",
  //   handle: "policy",
  //   key: "policy",
  // },
]

const Footer = () => {
  return (
    <>
      <div className="bg-primary bottom-0 grid md:grid-cols-3 text-white  justify-items-center py-10 space-y-16 md:space-y-0">
        <FooterSection title="Explore">
          <div className="grid grid-cols-1">
            {footerLinks &&
              footerLinks.map(item => (
                <Link
                  to={`/${item.handle}`}
                  key={item.key}
                  className="text-md uppercase"
                >
                  {item.name}
                </Link>
              ))}
          </div>
        </FooterSection>
        <FooterSection title="Contact">
          <div className="text-xl text-center">
            <p>57 King Street, Sioux Lookout, ON</p>
            <p className="">
              Call Us:{" "}
              <a href="tel:+18077385812" className="underline">
                (807) 738 5812
              </a>
            </p>
            <a href="mailto:rbutler@windigo.on.ca" className="underline">
              Email Us
            </a>
          </div>
        </FooterSection>
        <FooterSection title="Stay Up to Date">
          <div className="flex flex-col items-center text-5xl ">
            <div className="flex md:justify-start space-x-5 mb-10 ">
              <ExternalLink
                to="https://www.facebook.com/winks.activewellness.3"
                className=""
              >
                <FaFacebook />
              </ExternalLink>
            </div>
            {/* <p className="">Join Our Newsletter:</p>
            <FooterForm className="" /> */}
          </div>
        </FooterSection>
      </div>
      <div className="bg-green-700 h-full text-xl grid grid-cols-1 place-items-center text-white py-3">
        <div className="block">
          <div className="text-xl font-bold text-center mb-3">
            Funded by CHOOSE LIFE
          </div>
          <div className="inline-block leading-10">
            <RiCopyrightLine />
          </div>
          <div className="inline-block">
            <p className="">
              {new Date().getFullYear()} W.I.N.K.S. All Rights Reserved.
            </p>
          </div>
          <p className="text-center mt-3">
            Website by{" "}
            <ExternalLink to="https://www.borealisweb.ca">
              Borealis Web Development
            </ExternalLink>
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer
