import React from "react"
import DropdownMenu from "./dropDownMenu"

import { Link } from "gatsby"

// default to empty strings because I may use it for other purposes (that don't use these props)
const NavbarItem = ({
  handle = null,
  itemKey = "",
  name = "",
  fluid = null,
  dropdown = null,
  dropdownWrap = false,
  className = "text-green-500",
  children = null,
}) => {
  const navLinkClass = `text-tertiary text-center group-hover:bg-blue-light group-hover:text-secondary   text-xl h-full grid items-center  ${className}`
  // console.log(handle)
  return (
    // "group" allows for child elements to be affected by hover over a parent element
    // https://tailwindcss.com/docs/pseudo-class-variants#group-hover
    // https://tailwindcss.com/docs/configuring-variants
    <div className="group relative inline-block h-full ">
      {/* If no handle is provided, render a visually identical non-link item. Empty string is OK (i.e. Index Page) */}
      {handle != null ? (
        name == "WINKS" ? (
          <Link to={`/${handle}`} key={itemKey}>
            {children}
          </Link>
        ) : (
          <Link
            to={`/${handle}`}
            key={itemKey}
            className={`uppercase border-transparent hover:border-tertiary border-b-2 ${navLinkClass}  `}
            activeClassName="text-secondary"
          >
            <div className="">{name}</div>
          </Link>
        )
      ) : (
        <div
          className={`uppercase cursor-pointer text-tertiary text-center h-full grid items-center text-xl `}
        >
          {name}
        </div>
      )}
      {dropdown && (
        <>
          <DropdownMenu dropdown={dropdown} dropdownWrap={dropdownWrap} />
        </>
      )}
    </div>
  )
}

export default NavbarItem
