import React from "react"

const WinksLogoTextOnly = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 381.000000 97.000000"
      preserveAspectRatio="xMidYMid meet"
      className={`${className}`}
    >
      <g
        transform="translate(0.000000,97.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M43 953 c-13 -3 -23 -12 -23 -21 0 -49 174 -869 188 -885 13 -14 29
-17 81 -15 l66 3 18 85 c10 47 47 224 82 395 35 171 64 305 65 298 1 -25 131
-710 141 -740 5 -17 17 -34 25 -37 15 -6 134 -9 134 -3 0 1 45 204 99 452 55
247 97 453 95 457 -3 4 -27 8 -53 8 l-47 0 -63 -297 c-34 -164 -72 -350 -85
-413 -13 -63 -26 -108 -29 -100 -3 8 -38 186 -77 395 -39 208 -74 383 -76 387
-9 14 -131 9 -137 -4 -2 -7 -39 -187 -82 -401 -44 -213 -82 -384 -86 -380 -3
5 -16 64 -28 133 -46 267 -124 650 -135 664 -13 17 -43 25 -73 19z"
        />
        <path
          d="M3384 950 c-61 -19 -128 -74 -156 -130 -52 -99 -29 -209 59 -276 22
-17 96 -55 163 -85 91 -40 136 -66 172 -100 45 -42 48 -49 48 -95 0 -90 -54
-140 -163 -151 -71 -7 -149 10 -232 53 l-70 35 -22 -26 c-37 -42 -30 -57 45
-97 90 -48 155 -62 266 -56 113 6 182 34 231 91 40 47 55 91 55 165 0 124 -70
189 -323 297 -108 46 -147 86 -147 148 0 59 30 104 87 129 61 27 164 21 254
-16 69 -28 80 -25 97 24 13 36 5 43 -74 71 -67 24 -238 35 -290 19z"
        />
        <path d="M1200 490 l0 -460 50 0 50 0 0 460 0 460 -50 0 -50 0 0 -460z" />
        <path
          d="M1570 490 l0 -460 45 0 45 0 0 393 0 392 21 -45 c11 -25 104 -201
206 -393 l186 -347 64 0 63 0 0 460 0 460 -34 0 c-70 0 -69 10 -64 -406 l4
-372 -87 166 c-48 92 -141 266 -206 387 l-118 220 -62 3 -63 3 0 -461z"
        />
        <path
          d="M2460 490 l0 -461 53 3 52 3 3 207 2 206 50 7 c91 13 157 -14 194
-78 13 -23 47 -100 75 -172 64 -164 71 -175 121 -175 22 0 45 7 54 16 15 14
14 18 -8 47 -13 18 -47 93 -76 167 -72 183 -111 240 -175 256 -20 5 -12 21
106 211 71 113 126 210 123 214 -3 5 -24 9 -48 9 -55 0 -59 -6 -181 -205 -54
-88 -105 -168 -113 -177 -11 -13 -30 -18 -68 -18 l-54 0 -2 198 -3 197 -52 3
-53 3 0 -461z"
        />
      </g>
    </svg>
  )
}

export default WinksLogoTextOnly
