import React from "react"

const WinksLogoCircleOnly = ({ className = "h-full fill-black" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 244.000000 242.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,242.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M1021 2405 c-398 -70 -752 -347 -909 -710 -267 -620 11 -1328 629
-1600 154 -67 243 -86 434 -92 192 -6 284 6 439 58 625 208 968 900 759 1529
-116 349 -404 641 -748 760 -44 16 -114 36 -155 45 -106 23 -343 28 -449 10z
m437 -49 c244 -53 442 -164 613 -342 114 -119 209 -272 259 -419 l20 -59 -32
30 c-18 16 -107 99 -198 184 -91 85 -175 163 -187 173 -22 19 -24 18 -355
-169 -183 -103 -337 -187 -343 -187 -5 0 -142 75 -304 167 -162 91 -300 166
-307 166 -7 0 -119 -54 -249 -121 l-235 -121 19 45 c30 70 101 180 165 256
175 211 424 354 701 406 100 18 329 14 433 -9z m682 -677 l225 -211 12 -61
c19 -94 18 -307 -1 -410 -19 -99 -64 -234 -107 -319 l-29 -58 -65 0 -65 0 0
202 0 202 54 -53 c50 -49 76 -59 76 -28 0 7 -29 42 -65 77 l-65 64 0 70 0 70
53 -52 c44 -43 56 -50 65 -39 9 11 -2 28 -53 84 l-65 71 0 68 0 68 53 -52 c48
-47 55 -51 67 -37 12 14 4 25 -56 86 -37 38 -73 69 -79 69 -6 0 -42 -31 -79
-69 -60 -61 -68 -72 -56 -86 12 -14 18 -11 62 32 l48 47 0 -69 0 -69 -62 -63
c-53 -54 -60 -64 -48 -78 12 -14 18 -11 62 32 l48 47 0 -70 0 -69 -60 -67
c-49 -54 -57 -68 -46 -77 11 -10 23 -2 60 36 l46 48 0 -197 0 -198 -195 0
-195 0 0 202 0 202 53 -52 c44 -43 56 -50 65 -39 9 10 -2 27 -53 77 l-65 64 0
76 1 75 48 -54 c28 -31 53 -51 60 -47 21 13 11 32 -49 91 l-60 59 0 75 0 75
50 -49 c39 -39 52 -47 62 -37 10 10 -3 27 -57 82 -38 39 -74 70 -80 70 -10 0
-65 -54 -124 -122 -22 -25 -22 -28 -7 -34 13 -5 32 7 67 42 l49 48 0 -68 0
-68 -65 -72 c-49 -56 -62 -75 -53 -84 9 -9 24 0 65 40 l53 52 0 -75 0 -75 -60
-59 c-61 -61 -71 -79 -46 -88 9 -4 33 14 60 43 l46 49 0 -199 0 -200 -69 0
-69 0 -121 190 c-67 105 -121 195 -121 201 0 5 12 28 27 50 19 28 23 43 15 51
-7 7 -18 -1 -39 -29 l-28 -40 -28 40 c-21 28 -32 36 -39 29 -8 -8 -4 -23 15
-51 15 -22 27 -45 27 -50 0 -5 -55 -96 -123 -203 l-123 -193 -62 -3 -62 -3 0
208 0 207 53 -52 c40 -40 56 -49 65 -40 9 9 -4 27 -53 80 l-65 68 0 72 0 72
53 -52 c44 -43 56 -50 65 -39 9 10 -2 27 -53 77 l-65 64 0 75 0 75 53 -52 c42
-42 56 -50 64 -40 9 10 -5 30 -58 86 -38 39 -75 72 -82 72 -6 0 -41 -32 -75
-70 -53 -59 -62 -73 -52 -86 11 -12 20 -7 61 38 l48 53 1 -75 0 -74 -62 -63
c-55 -55 -60 -64 -47 -79 12 -15 18 -12 61 37 l48 54 0 -74 0 -74 -61 -64
c-52 -54 -59 -66 -48 -79 11 -13 19 -9 61 33 l48 47 0 -202 0 -202 -210 0
-210 0 0 207 0 207 54 -53 c50 -49 76 -59 76 -28 0 7 -29 42 -65 77 l-65 64 0
70 0 70 53 -52 c43 -43 56 -50 65 -40 8 11 -3 29 -53 83 l-64 70 -1 70 0 69
53 -52 c48 -47 55 -51 67 -37 12 14 4 25 -56 86 -37 38 -73 69 -79 69 -6 0
-42 -31 -80 -70 -63 -63 -68 -71 -54 -85 14 -14 20 -11 62 34 l47 50 0 -72 0
-71 -62 -63 c-53 -54 -60 -64 -48 -78 12 -14 18 -11 62 32 l48 47 0 -69 0 -68
-59 -67 c-45 -51 -57 -70 -48 -79 8 -8 23 0 59 36 l48 47 0 -202 0 -202 -66 0
-65 0 -44 92 c-118 251 -146 529 -78 792 l28 108 255 130 255 131 304 -173
305 -173 55 31 c31 17 180 101 331 186 151 85 280 155 285 156 6 0 111 -95
235 -211z m-733 -971 l53 -83 -44 -8 c-62 -12 -120 18 -147 76 -10 23 -19 52
-19 65 0 32 -26 27 -39 -8 -5 -17 -33 -55 -62 -85 -47 -50 -56 -55 -95 -55
-24 0 -44 3 -44 7 0 4 24 44 52 90 l53 83 120 0 120 0 52 -82z m793 -148 c-6
-11 -32 -46 -57 -78 -25 -31 -47 -58 -48 -59 -1 -2 -35 6 -76 17 -94 25 -119
25 -224 -2 l-85 -21 -85 21 c-47 12 -99 22 -115 22 -16 0 -68 -10 -115 -22
l-85 -21 -85 21 c-106 27 -124 27 -230 0 l-85 -21 -85 21 c-106 27 -124 27
-229 1 l-84 -22 -99 24 c-99 23 -100 24 -137 74 l-37 50 608 5 c1424 12 1365
13 1353 -10z m-1388 -161 c35 -19 145 -16 216 5 66 20 98 18 222 -14 50 -13
107 -7 188 18 54 16 92 13 212 -18 50 -13 109 -7 184 17 53 17 123 13 190 -10
l38 -13 -50 -42 c-39 -33 -58 -42 -88 -42 -22 0 -57 -5 -79 -11 -126 -33 -133
-33 -232 -9 -100 24 -94 25 -252 -11 -48 -11 -64 -10 -148 11 -101 25 -83 25
-257 -11 -46 -10 -65 -9 -138 10 -103 26 -115 26 -212 1 l-77 -21 -47 40 c-26
21 -63 56 -82 77 -33 35 -34 36 -10 30 104 -30 162 -30 239 0 41 16 148 12
183 -7z m-3 -159 c93 -25 104 -25 212 2 l89 22 89 -22 c48 -12 98 -22 111 -22
13 0 63 10 111 22 l89 22 89 -22 c108 -27 119 -27 212 -2 41 11 81 20 89 20
23 0 -84 -68 -174 -110 l-81 -39 -64 16 c-55 14 -72 15 -120 4 -158 -36 -147
-36 -249 -11 -86 21 -101 22 -151 11 -156 -36 -150 -35 -230 -16 -42 10 -110
36 -151 56 -112 57 -111 55 -54 72 66 21 95 20 183 -3z m392 -160 c94 -25 115
-25 216 1 59 15 94 19 120 14 38 -8 38 -8 -37 -26 -156 -39 -304 -44 -475 -17
l-70 11 60 18 c78 23 97 23 186 -1z"
        />
      </g>
    </svg>
  )
}

export default WinksLogoCircleOnly
