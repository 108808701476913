import React from "react"

const ExternalLink = ({ to = "#", className = "", children = null }) => {
  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={`underline ${className}`}
    >
      {children}
    </a>
  )
}

export default ExternalLink
