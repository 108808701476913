/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
// import "../styles/components/_layout.scss"

import NavBar from "./navbar/navbar"
import Footer from "./footer"
import BackToTop from "./BackToTop"

const Layout = ({ children }) => {
  // const [showScroll, setShowScroll] = useState(false)

  // const checkScrollTop = () => {
  //   if (!showScroll && window.pageYOffset > 300) {
  //     setShowScroll(true)
  //   } else if (showScroll & (window.pageYOffset <= 300)) {
  //     setShowScroll(false)
  //   }
  // }

  // window.addEventListener("scroll", checkScrollTop)

  // const scrollTop = () => {
  //   console.log("Hello")
  //   // window.scrollTo({ top: 0, behavior: "smooth" })
  // }

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll)

  //   return window.removeEventListener("scroll", handleScroll)
  // }, [offset, handleScroll])
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <NavBar />
      <div
        className={`relative top-ten bg-white font-sans text-white z-0 flex-grow flex flex-col justify-between`}
      >
        {children}
        {/* <BackToTop className="z-30" /> */}
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
