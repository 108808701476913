import React from "react"

const WaveDivider = ({ className }) => {
  return (
    <svg
      viewBox="0 0 103 8"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M92.8213 1.54498C86.8162 -0.34333 80.9827 -0.514995 74.9776 1.54498C69.4873 3.26163 63.9969 3.26163 58.6782 1.54498C52.5015 -0.514995 46.4964 -0.514995 40.3198 1.54498C35.001 3.26163 29.6822 3.26163 24.3634 1.54498C18.1868 -0.514995 12.1817 -0.514995 6.00507 1.54498C3.94619 2.23164 2.05888 2.57497 0 2.74663V6.8666C2.5736 6.69493 4.97564 6.17994 7.37767 5.49328C13.2112 3.60497 18.7015 3.4333 24.3634 5.49328C30.0254 7.72493 35.8589 7.55326 41.6924 5.49328C47.1827 3.4333 52.8447 3.4333 58.5066 5.49328C64.1685 7.55326 70.002 7.55326 75.8355 5.49328C81.669 3.4333 87.3309 3.4333 93.1644 5.66495C96.4243 6.8666 99.5127 7.20992 102.773 7.03826V2.74663C99.5127 2.9183 96.2528 2.57497 92.8213 1.54498Z" />
    </svg>
  )
}

export default WaveDivider
